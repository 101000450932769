
import { Component, Vue } from "vue-property-decorator";
import EnterpriseEnquireForm from "@/components/Academy/EnterpriseEnquire/EnterpriseEnquireForm.vue";
import AcademyParticipantFinish from "@/components/Academy/AcademyParticipant/AcademyParticipantFinish.vue";
@Component({
  components: { EnterpriseEnquireForm, AcademyParticipantFinish },
})
export default class EnterpriseEnquire extends Vue {
  //data
  formVisible = true;
  finishVisible = false;
  isLoading = false;
  viewWidth = 0;
  //hook
  mounted(): void {
    this.viewWidth = window.innerWidth;
    window.onresize = () => {
      this.viewWidth = window.innerWidth;
    };
  }
  //methods
  send(): void {
    this.isLoading = true;
    setTimeout(() => {
      document.documentElement.scrollTop = 0;

      this.formVisible = false;
      this.finishVisible = true;
    }, 1000);
  }
}
