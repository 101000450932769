
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import tigermaster from "fdtigermaster-client-sdk";
import Loading from "@/components/Utility/Loading.vue";
import {
  validateTWPhoneNum,
  validateEmail,
} from "@/models/Validator/Validator";

@Component({
  components: {
    Loading,
  },
})
export default class EnterpriseEnquireForm extends Vue {
  @Prop() public send!: any;

  isLoading = false;
  helpSelected = [];
  demand = "";
  companyName = "";
  userName = "";
  phone = "";
  email = "";
  sourceSelected = [];
  isWarningShow = false;
  checkState: boolean[] = [true, true, true, true, true, true, true];
  // selectedSource: string | null = null;
  // customInfoSource = "";
  customInputPlaceholder = "";
  // showCustomInput = false;
  sourceStr = "";

  // sourceOptions: string[] = [
  //   "廣告電台",
  //   "Facebook",
  //   "名人推薦",
  //   "新聞媒體",
  //   "Podcast",
  //   "親友介紹",
  //   "Google搜尋",
  //   "Youtube",
  //   "電視廣告",
  //   "LINE廣告",
  //   "其他",
  // ];

  // handleSourceChange() {
  //   switch (this.selectedSource) {
  //     case "廣告電台":
  //       this.customInputPlaceholder = "請輸入廣告節目名稱";
  //       this.showCustomInput = true;
  //       break;
  //     case "名人推薦":
  //       this.customInputPlaceholder = "請輸入名人名稱";
  //       this.showCustomInput = true;
  //       break;
  //     case "新聞媒體":
  //       this.customInputPlaceholder = "請輸入媒體名稱";
  //       this.showCustomInput = true;
  //       break;
  //     case "Podcast":
  //       this.customInputPlaceholder = "請輸入Podcast名稱";
  //       this.showCustomInput = true;
  //       break;
  //     case "其他":
  //       this.customInputPlaceholder = "請輸入如何得知師虎來了";
  //       this.showCustomInput = true;
  //       break;
  //     default:
  //       this.showCustomInput = false;
  //       this.customInputPlaceholder = "";
  //   }
  // }

  async createEnterpriseEnquire(): Promise<void> {
    const sourceStr = this.sourceSelected.join(",");
    const needToCheck = [
      this.helpSelected.length,
      this.demand,
      this.companyName,
      this.userName,
      this.phone,
      this.email,
      sourceStr,
    ];
    for (let i = 0; i < needToCheck.length; i++) {
      this.checkState[i] = Boolean(needToCheck[i]);
    }

    if (this.checkState.includes(false)) {
      this.isWarningShow = true;
      document.documentElement.scrollTop = 0;
      return;
    }

    this.isLoading = true;
    await tigermaster.enquire.createEnterpriseEnquire({
      demand: this.demand,
      companyName: this.companyName,
      userName: this.userName,
      phone: this.phone,
      email: this.email,
      infoSource: sourceStr,
    });

    this.send();
  }

  validator(index: number, val: string): void {
    switch (index) {
      case 4:
        this.checkState[4] = validateTWPhoneNum(val);
        break;
      case 5:
        this.checkState[5] = validateEmail(val);
        break;
      default:
        this.checkState[index] = Boolean(val);
        console.log("this.checkState[index]--->", this.checkState[index]);
    }
  }

  @Watch("helpSelected")
  watchHelp(val: string[]): void {
    this.validator(0, val.join(""));
  }

  @Watch("sourceSelected")
  watchSource(val: string[]): void {
    this.validator(6, val.join(""));
  }
}
